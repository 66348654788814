import { CommonEnvironment } from './environment.common';

export class Environment extends CommonEnvironment {

  static adsPrice: number = 0;
  static firebase: any = {
    apiKey: "AIzaSyAAVyMM46v83tmRiVNPFuFjoqpq-Kef0Zg",
    authDomain: "hvaskjernordfosen.no",
    projectId: "hva-skjer-nord-fosen",
    storageBucket: "hva-skjer-nord-fosen.appspot.com",
    messagingSenderId: "486574370105",
    appId: "1:486574370105:web:008134a9783d549c9ecce8",
    measurementId: "G-2ZXP6BGCH4"
  };
  static facebookAppID: string = '3395114827434026';
  static siteURL: string = 'https://hvaskjernordfosen.no';
  static contact: any = {
    email: 'post@hvaskjernordfosen.no'
  };
  static content: any = {
    siteName: 'Hva skjer i Nord Fosen?',
    siteNameTranslatable: {
      nb: 'Hva skjer i Nord Fosen?',
      en: 'What\'s on in North Fosen?',
    },
    titleFrontpage: {
      en: 'Upcoming events',
      nb: 'Kommende arrangementer'
    },
    welcomeMessage:  {
      en: 'Are you wondering what happens tonight, for the weekend or when you come home on holiday? At hvaskjernordfosen.no you get an overview of all the events that are going on - without having to look around on many different pages! With this overview, it will be easier to prioritize what you will be doing while the babysitter take care of the kids, what to find on the weekend or what you can buy tickets for; Whether it\'s for yourself or someone you love.',
      nb: 'Lurer du på hva som skjer i kveld, til helga eller når du kommer hjem på ferie? På hvaskjernordfosen.no får du en oversikt over alle arrangement som går av stabelen - uten at du trenger å lete rundt på mange ulike sider! Med én oversikt blir det enklere å prioritere hva du skal bruke barnavakta på, hva dere skal finne på i helga eller hva du kan kjøpe billetter til; enten det er til deg selv eller noen du er glad i.'
    }
  };
  static owner: any = {
    name: "Bygdilag",
    link: 'https://bygdilag.no/'
  };
  static ui: any = {
    ... CommonEnvironment.ui,
    showOnMenu: [
      ...CommonEnvironment.ui.showOnMenu,
      {
        name_en: 'Newsletters',
        name_nb: 'Nyhetsbrev',
        internal: true,
        link: '/newsletter',
        icon: 'email',
        loggedIn: true,
        admin: true
      }
    ]
  };
  static placeholderImage: string = 'hva-skjer-nord-fosen.jpeg';
  static town: any = {
    name: 'Fosen',
    hint: 'Fosen Norway',
    lat: 64.24528608048118, 
    lng: 10.32683677643918
  };
};

export const environment: any = Environment;
